.loader{
  width: 100%;
  margin:auto;
  left:0;
  right:0;
  top:0;
  bottom:0;
  position:absolute;
}
.spinner-grow{
  margin:auto;
  left:0;
  right:0;
  top:0;
  bottom:0;
  position:fixed;
}
