.main{
  background-color: #FFFFFF;

}

#root{

  background-size:cover;
 position: relative;
  min-height: 100vh;


}
.mf{
background-color: #1f4288;
margin: 0;
}
.main-form{
  background-color: #FFFFFF;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  margin-bottom: 3rem;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
.form-validate{
   padding-top: 2rem;
}

.btn-main-form{
  background-color: #039D53 !important;
  border-color: #039D53 !important;


}

.content{
    	margin: auto;
      background-color: #FFFFFF;
      margin-top: 1rem;
    	padding: 3rem;
      padding-left: 4px;

    }
.nav-link{
  color: #039D53!important;
}

.photo-control img{
  width:auto !important;
  height: 210px !important;
  padding-bottom: 2rem;
}

.rbt-close-content{
  display:none !important
}
/* .btn-margin{
  margin: 1rem 1rem 1rem
} */

#footer {
  position: absolute;
  /* bottom: 0; */
  width: 100%;
  height: 2.5rem;    
   /* position: fixed; */
   bottom: 0;    
   background-color: #FFFFFF;   
}

.foop{
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.especialBtn{
  font-size: 30px!important;
}

.profile-user-img-container {
    position: relative;
    overflow: hidden;
}

.profile-user-img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}

.profile-user-img-border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10%; /* Cambia a 10% si deseas que cubra el 10% del área inferior */
    background-color: white; /* Color del borde */
}

